import React, { useEffect, useState } from "react";
import {
  Box,
  TextInput,
  Button,
  Group,
  Stack,
  Image,
  Loader,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./app.css";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      <Loader color="cap-navy" />
      return;
    }
    if (user) navigate("/dashboard/settings");
  }, [user, loading, navigate]);

  return (
    <Box className="center" sx={{ maxWidth: 300 }} mx="auto">
      <Stack gap="1">
        <Image
          radius="md"
          src="../../captiva-mark.png"
          style={{ height: "8em", width: "8em", margin: "auto" }}
        />
        <TextInput
          required
          label="Email"
          placeholder="your@email.com"
          size="md"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></TextInput>
        <TextInput
          required
          type="password"
          label="Password"
          placeholder="password"
          size="md"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></TextInput>
        <Group position="center" mt="md">
          {loginLoading ? (
            <>
              <Loader color="cap-navy" />
            </>
          ) : (
            <>
              <Button
                color="cap-navy"
                size="md"
                style={{ width: "100%" }}
                type="submit"
                onClick={async() =>  {
                  setLoginLoading(true);
                  await logInWithEmailAndPassword(email, password)
                  setLoginLoading(false);
                }
                }
              >
                Submit
              </Button>
              <Link to="/reset">Forgot Password</Link>
            </>
          )}
        </Group>
      </Stack>
    </Box>
  );
}

//     </button>
//     {/* <Button color="cap-navy" className="login__btn login__google" onClick={signInWithGoogle}>
//       Login with Google
//     </button>
//     <div>
//       <Link to="/reset">Forgot Password</Link>
//     </div>
//     <div>
//       Don't have an account? <Link to="/register">Register</Link> now.
//     </div> */}
