import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AppShell,
  Navbar,
  Header,
  Text,
  useMantineTheme,
  Button,
  Image,
  Loader,
  ThemeIcon,
  UnstyledButton,
  Group,
  MediaQuery,
  Burger,
  Space,
  Divider,
  ScrollArea,
} from "@mantine/core";

import { logout } from "../firebase";

import "./app.css";

function MainLink({ icon, color, label, target, selected, clickHandler }) {
  return (
    <UnstyledButton
      disabled
      component={Link}
      to={target}
      onClick={() => clickHandler()}
      sx={(theme) => ({
        display: "block",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        border: selected ? "0.5px solid" : "0.5px solid transparent",
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="fill">
          {icon}
        </ThemeIcon>
        <Text size="md">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const TemplateLinksData = [
  {
    icon: <Image src="../../visibility.svg" size={16} />,
    label: "Tank readings",
    target: "/dashboard/capturedTankLevelReading",
    color: "orange",
  },
  {
    icon: <Image src="../../move.svg" size={16} />,
    label: "Fuel transfers",
    target: "/dashboard/capturedFuelTankTransfer",
    color: "green",
  },
  {
    icon: <Image src="../../forward.svg" size={16} />,
    label: "Onsite tank issues",
    target: "/dashboard/capturedFuelTankIssue",
    color: "cyan",
  },
  {
    icon: <Image src="../../forward.svg" size={16} />,
    label: "Onroad refuels",
    color: "red",
    target: "/dashboard/capturedRefuel",
  },
  {
    icon: <Image src="../../gravity.svg" size={16} />,
    label: "Bulk receipts",
    color: "yellow",
    target: "/dashboard/capturedBulkFuelReceived",
  },
];

export function TemplateLinks({ selectedPath, setBurger }) {
  const links = TemplateLinksData.map((link) => (
    <MainLink
      {...link}
      key={link.label}
      selected={selectedPath === link.target}
      clickHandler={() => setBurger(false)}
    />
  ));
  return <div>{links}</div>;
}

function ShellWithNavigation({ user, children }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const currentPath = window.location.pathname;

  return (
    <AppShell
      styles={{
        main: {
          background: "white",
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 300, lg: 300, }}
          styles={{overflowY: "scroll"}}
        >
          <ScrollArea scrollbars="y">
            <Navbar.Section>
              <Text size="xl">Captured data</Text>
                <TemplateLinks
                  selectedPath={currentPath}
                  setBurger={setOpened}
                />
            </Navbar.Section>
            <Divider></Divider>
            <Space h="sm" />
            <Navbar.Section>
              <Text size="xl">Settings</Text>
                <MainLink
                  icon={<Image src="../../building.svg" size={16} />}
                  label="Company overview"
                  target="/dashboard/settings"
                  color="grape"
                  selected={currentPath === "/dashboard/settings"}
                  clickHandler={() => setOpened(false)}
                />
                <MainLink
                  icon={<Image src="../../local_shipping.svg" size={16} />}
                  label="Company assets"
                  target="/dashboard/assets"
                  selected={currentPath === "/dashboard/assets"}
                  color="cap-purple"
                  clickHandler={() => setOpened(false)}
                />
                <MainLink
                  icon={<Image src="../../summarize.svg" size={16} />}
                  label="Notifications"
                  target="/dashboard/notifications"
                  selected={currentPath === "/dashboard/notifications"}
                  color="yellow"
                  clickHandler={() => setOpened(false)}
                />
                {!(/iPhone|iPod/.test(navigator.userAgent)) && <>
                  <MainLink
                    icon={<Image src="../../table.svg" size={16} />}
                    label="Reports"
                    target="/dashboard/reports"
                    selected={currentPath === "/dashboard/reports"}
                    clickHandler={() => setOpened(false)}
                  />
                  <MainLink
                    icon={<Image src="../../table_chart.svg" size={16} />}
                    label="Advanced reports"
                    target="/dashboard/advanced-reports"
                    color="orange"
                    selected={currentPath === "/dashboard/advanced-resports"}
                    clickHandler={() => setOpened(false)}
                  />
                </>}
                <MainLink
                  icon={<Image src="../../account.svg" size={16} />}
                  label="User"
                  target="/dashboard/account"
                  color="red"
                  selected={currentPath === "/dashboard/account"}
                  clickHandler={() => setOpened(false)}
                />
            </Navbar.Section>
          </ScrollArea>
        </Navbar>
      }
      header={
        <Header height={70} p="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="md"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div style={{ height: "2em" }}>
              <Image
                radius="md"
                fit="contain"
                src="../../captiva-logo.png"
                style={{ width: "9em", objectFit: "contain" }}
              />
            </div>
            {/* <Text
              component="span"
              align="center"
              // variant="gradient"
              // gradient={{ from: "indigo", to: "red", deg: 45 }}
              size="m"
              weight={700}
              style={{
                color: "#0339A6",
                width: "8em",
              }}
            >
              FUEL SENSE
            </Text> */}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Text>{user?.email}</Text> */}
              <Button
                onClick={logout}
                color="cap-purple"
                style={{ marginLeft: "1em" }}
              >
                Logout
              </Button>
            </div>
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}

export function AppShellResponsive({ children, loading, user }) {
  return (
    <>
      {loading ?? (
        <div class={{}}>
          <Loader color="#cc185f" size="lg" variant="bars" />
        </div>
      )}
      {user ? (
        <ShellWithNavigation user={user}>{children}</ShellWithNavigation>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
